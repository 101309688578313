$(function () {
  $('[data-toggle="popover"]').popover();

  AOS.init({
    useClassNames: true,
    initClassName: false,
    animatedClassName: 'animated',
    offset: 20,
  });


  const partners = [
    {
      name: '360 影视',
      icon: require('../image/logo/360yingshi.png'),
    },
    {
      name: 'APUS',
      icon: require('../image/logo/apus.png'),
    },
    {
      name: '贝壳',
      icon: require('../image/logo/beike.png'),
    },
    {
      name: 'blued',
      icon: require('../image/logo/blued.png'),
    },
    {
      name: 'Boss 直聘',
      icon: require('../image/logo/boss.png'),
    },
    {
      name: '蛋壳公寓',
      icon: require('../image/logo/danke.png'),
    },
    {
      name: '凤凰新闻',
      icon: require('../image/logo/fenghuangxinwen.png'),
    },
    {
      name: '花椒直播',
      icon: require('../image/logo/huajiao.png'),
    },
    {
      name: '快看漫画',
      icon: require('../image/logo/kuaikanmanhua.png'),
    },
    {
      name: '链家',
      icon: require('../image/logo/lianjia.png'),
    },
    {
      name: '瑞幸咖啡',
      icon: require('../image/logo/luckin.png'),
    },
    {
      name: '蚂蜂窝',
      icon: require('../image/logo/mafengwo.png'),
    },
    // {
    //   name: '芒果看点',
    //   icon: require('../image/logo/mangguokandian.png'),
    // },
    {
      name: '小米',
      icon: require('../image/logo/mi.png'),
    },
    {
      name: '墨迹天气',
      icon: require('../image/logo/moji.png'),
    },
    {
      name: '网易',
      icon: require('../image/logo/netease.png'),
    },
    {
      name: '蜻蜓 FM',
      icon: require('../image/logo/qingtingfm.png'),
    },
    {
      name: '齐齐直播',
      icon: require('../image/logo/qiqi.png'),
    },
    {
      name: '趣头条',
      icon: require('../image/logo/qutoutiao.png'),
    },
    {
      name: '松果出行',
      icon: require('../image/logo/songguochuxing.png'),
    },
    {
      name: '途牛旅行',
      icon: require('../image/logo/tuniulvxing.png'),
    },
    {
      name: '微博',
      icon: require('../image/logo/weibo.png'),
    },
    {
      name: '映客直播',
      icon: require('../image/logo/yingke.png'),
    },
    {
      name: '一直播',
      icon: require('../image/logo/yizhibo.png'),
    },
    {
      name: '优信二手车',
      icon: require('../image/logo/youxin.png'),
    },
  ].map(function (item) {
    return '<div class="partner-carousel-item"><div class="img-container"><img data-src="' + item.icon + '" title="' + item.name + '"/></div></div>';
  });

  const items = [];

  for (var i = 0; i < partners.length; i += 2) {
    var column = [partners[i], partners[i + 1]].filter(function (item) {
      return item;
    }).join('');
    items.push('<div>' + column + '</div>')
  }

  $('.partner-carousel .owl-carousel').html(items.join(''));

  $('#navbar-dropdown .dropdown-item').on('click', function () {
    var selector = $(this).attr('href');
    scrollTo(selector);
    $('#navbar-dropdown').collapse('toggle');
  });

  $('.effect-carousel .owl-carousel').owlCarousel({
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    nav: true,
    dots: true,
    items: 1,
  });

  $('.partner-carousel .owl-carousel').owlCarousel({
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 3,
      },
      768: {
        items: 4,
      },
      980: {
        items: 5,
      },
      1200: {
        items: 6,
      },
    }
  });

  $('.guide-trigger').click(function () {
    scrollTo('#guide');
  });
  $('.problem-trigger').click(function () {
    scrollTo('#problem');
  });
  $('.product-trigger').click(function () {
    scrollTo('#product');
  });
  $('.ascription-trigger').click(function () {
    scrollTo('#ascription');
  });

  $('.brand').click(function () {
    $('html,body').animate({scrollTop: 0}, 1200);
  });

  function scrollTo (selector) {
    try {
      var distance = $(selector).offset().top;
      var offset = $('#navbar .container').height();
      var top = distance - offset;
      $('html,body').animate({scrollTop: top}, 1200);
    } catch (error) {}
  }

  $('#navbar .nav-link').on('click', function (event) {
    var selector = $(this).attr('href');
    scrollTo(selector);
  });

  $('#navbar .nav-link').hover(function () {
    $('.nav').addClass('hover');
  }, function (event) {
    $('.nav').removeClass('hover');
  });

  lozad('img[data-src]', {
    rootMargin: '10px 0px',
    threshold: 0.1
  }).observe();

});
